
import { Component, Ref, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import MediaOceanInvoice from '@/models/MediaOceanInvoice'
import DataTable from '@/components/DataTable/index.vue'
import { clone } from 'lodash'
import Api from '@/models/Api'
import WebMessage from '@/models/WebMessage'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import MediaOceanInvoiceExport from './Components/MediaOceanInvoiceExport.vue'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    MediaOceanInvoiceExport,
  },
})
export default class MediaOceanInvoiceHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public invoice: any = null

  public sort_by: string = 'updated_at'

  public sort_desc: boolean = true

  public modal: boolean = false

  public file: any = null

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public query: string[] = []

  public selected: string[] = []

  public fieldFilters: any = {}

  public fields: any = []

  public query_settings: any = {}

  public ready = false

  public table_data: MediaOceanInvoice[] = []

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public createInvoice() {
    this.file = null
    this.modal = true
  }

  public confirmCreate() {
    if (this.file) {
      this.modal = false
      const api = new Api()
      api
        .form('media_ocean_invoice/import', {
          file: this.file,
        })
        .then((response: any) => {
          this.file = null
          WebMessage.success(
            'We are processing the file, we will let you know once it is complete!',
          )
        })
    } else {
      this.$router.push('/app/mediaocean_invoice')
    }
  }

  public invoices(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()
    return MediaOceanInvoice.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.table_data = result.data
      return result.data
    })
  }

  public mounted() {
    this.fields = clone(MediaOceanInvoice.tableFields)
    this.loadFilters()
  }

  public exportInvoices() {
    let Component = Vue.extend(MediaOceanInvoiceExport)
    let instance = new Component({
      propsData: {
        selected: this.selected,
        mode: this.selected.length === 0 ? 'broadcast_month' : 'selected',
      },
    })
    instance.$mount()
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.refresh()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'media_ocean_invoice',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('media_ocean_invoice').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'media_ocean_invoice',
      data: null,
    })
  }
}
