/** @format */

import Api from '@/models/Api'
import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import MediaOceanInvoiceModule from '@/store/model/MediaOceanInvoiceModule'
import moment from 'moment'
import MediaOceanInvoiceItem from './MediaOceanInvoiceItem'
import PaginateOptions from './interface/PaginateOptions'
import WebMessage from './WebMessage'
import { tableFields } from './metadata/MediaOceanInvoiceMetadata'

export default class MediaOceanInvoice {
  public id: string | null = null

  public name: string = ''

  public type: string = 'media_ocean'

  public invoice_mode: string = 'platform'

  public total_amount: number = 0

  public status: string = 'draft'

  public created_at: string = ''

  public updated_at: string = ''

  public media_plan_id: string | null = null

  public agency = {
    id: '',
    name: '',
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    address_line_4: '',
  }

  public station = {
    call_letters: '',
    media_type: 'TV',
    band: 'DV',
    name: 'Cast Iron Media',
    address_line_1: '100 S. Main St. Suite #200',
    address_line_2: 'Doylestown, PA 18901',
    address_line_3: '',
    address_line_4: '',
    compuer_system: '',
    gst_registration_number: '',
    qst_registration_number: '',
  }

  public payee = {
    name: 'Cast Iron Media',
    address_line_1: '100 S. Main St. Suite #200',
    address_line_2: 'Doylestown, PA 18901',
    address_line_3: '',
    address_line_4: '',
  }

  public header = {
    representative: '',
    sales_person: '',
    advertiser_name: '',
    product_name: '',
    date: moment().format('YYMMDD'),
    order_type: 'Cash',
    agency_estimate_code: '',
    number: '',
    broadcast_month: moment()
      .subtract(1, 'month')
      .format('YYMM'),
    start_date: '',
    end_date: '',
    schedule_start_date: '',
    schedule_end_date: '',
    contract_start_date: '',
    contract_end_date: '',
    billing_insctructions: '',
    rate_card_number: '',
    agency_comission_flag: '',
    sales_tax: '',
    audience_percent: '',
    rep_order_number: '',
    station_order_number: '',
    station_trade_order_number: '',
    station_advertiser_code: '',
    agency_advertiser_code: '',
    station_product_code: '',
    agency_product_code: '',
    station_contact: '',
    agency_contact: '',
    due_date: '',
    network: '',
    trading_partner_code: '',
    deal_number: '',
    rep_id: '',
    package_code: '',
    reference_invoice_number: '',
    reference_invoice_code: '',
    invoice_version_code: '',
    national_local_code: '',
    special_paying_rep_code: '',
  }

  public broadcasts: Array<MediaOceanInvoiceItem> = new Array<MediaOceanInvoiceItem>()

  public totals = {
    invoice_confirmed_cost: 0,
    actual_gross_billing: 0,
    agency_comission: 0,
    net_due: 0,
    reconciliation_dr: '',
    reconciliation_cr: '',
    reconciliation_total: '',
    state_tax: '',
    local_tax: '',
    prior_gross_balance: '',
    prior_net_balance: '',
    number_of_spots: '',
    gst: '',
    pst: '',
  }

  public trasmission = {
    invoices: '1',
    total: 0,
  }

  public get status_color(): string {
    if (this.status === 'draft') {
      return 'secondary'
    }
    if (this.status === 'ready') {
      return 'info'
    }
    if (['sent', 'processed'].includes(this.status)) {
      return 'success'
    }
    if (['invalid_amount', 'upload_error'].includes(this.status)) {
      return 'danger'
    }
    return 'warning'
  }

  public get is_legacy() {
    return this.broadcasts.some(i => i.legacy)
  }

  public get gross_amount() {
    return this.totals.actual_gross_billing / 100
  }

  public get gross_cash() {
    return (
      this.broadcasts
        .filter(b => b.order_type === 'cash')
        .reduce((carry: number, b) => carry + parseInt(b.rate), 0) / 100
    )
  }

  public get gross_trade() {
    return (
      this.broadcasts
        .filter(b => b.order_type === 'trade')
        .reduce((carry: number, b) => carry + parseInt(b.rate), 0) / 100
    )
  }

  public get net_amount() {
    return (this.totals.actual_gross_billing - this.totals.agency_comission) / 100
  }

  public get order_type() {
    let type = this.broadcasts.some(i => i.order_type === 'cash') ? 'Cash' : 'Trade'
    if (type === 'Cash') {
      type += this.broadcasts.some(i => i.order_type === 'trade') ? ' & Trade' : ''
    }

    return type
  }

  public updateTotals() {
    this.totals.invoice_confirmed_cost = 0

    let cash_invoice_confirmed_cost = this.broadcasts
      .filter(i => i.order_type === 'cash')
      .reduce((carry, i) => carry + parseInt(i.rate), 0)
    let trade_invoice_confirmed_cost = this.broadcasts
      .filter(i => i.order_type === 'trade')
      .reduce((carry, i) => carry + parseInt(i.rate), 0)

    this.totals.invoice_confirmed_cost = cash_invoice_confirmed_cost + trade_invoice_confirmed_cost
    this.totals.actual_gross_billing = cash_invoice_confirmed_cost + trade_invoice_confirmed_cost
    this.totals.agency_comission = Math.floor(cash_invoice_confirmed_cost * 0.15) + trade_invoice_confirmed_cost
    this.totals.net_due = this.totals.invoice_confirmed_cost - this.totals.agency_comission

    this.trasmission.total = this.totals.actual_gross_billing
  }

  public addItem(item?: MediaOceanInvoiceItem) {
    if (!item) item = new MediaOceanInvoiceItem()
    item.id = this.randomUUID()
    item.number = this.broadcasts.length + 1
    item.visible = true
    this.broadcasts.push(item)
  }

  public clone(number: number) {
    let item = this.broadcasts.find(i => i.number === number)
    item = MediaOceanInvoiceItem.toObject(item)
    item.number = this.broadcasts.length + 1
    item.id = this.randomUUID()
    item.visible = true
    item.has_listener = false
    this.broadcasts.push(item)
  }

  public removeItem(number: number) {
    const items = this.broadcasts.filter(i => i.number != number)

    let count = 1
    items.forEach(i => {
      i.number = count++
    })

    this.broadcasts = items
  }

  public moveItemUp(number: number) {
    if (number == 1) return

    this.broadcasts[number - 1].number--
    this.broadcasts[number - 2].number++

    this.broadcasts.sort((a, b) => a.number - b.number)
  }

  public moveItemDown(number: number) {
    if (number == this.broadcasts.length) return

    this.broadcasts[number - 1].number++
    this.broadcasts[number].number--

    this.broadcasts.sort((a, b) => a.number - b.number)
  }

  public buildRequest() {
    return JSON.parse(JSON.stringify(this))
  }

  public save() {
    const api = new Api()

    if (this.id) {
      return api
        .put(`media_ocean_invoice/${this.id}`, this.buildRequest())
        .then(this.onSave)
        .catch(this.onError)
    }
    return api
      .post('media_ocean_invoice', this.buildRequest())
      .then(this.onSave)
      .catch(this.onError)
  }

  public delete() {
    const api = new Api()

    return api
      .delete(`media_ocean_invoice/${this.id}`, {})
      .then(this.onDelete)
      .catch(this.onError)
  }

  public recalculate() {
    const api = new Api()

    return api
      .post(`media_ocean_invoice/${this.id}/recalculate`, {})
      .then(this.onSave)
      .catch(this.onError)
  }

  private onSave(response: any) {
    const invoice = MediaOceanInvoice.toObject(response.data.result.invoice)

    WebMessage.success(`Invoice "${invoice.name}" saved!`)

    return response
  }

  private onDelete(response: any) {
    const invoices = MediaOceanInvoice.filter(response.data.result.deleted)

    let message

    if (invoices.length == 1) {
      message = `Invoice "${invoices[0].name}" deleted!`
    } else {
      message = 'Invoices deleted!'
    }

    WebMessage.success(message)

    MediaOceanInvoice.module.delete(invoices)

    return response
  }

  private onError(error: any) {
    return error
  }

  public static toObject(data: any, cache: boolean = true): MediaOceanInvoice {
    const invoice = new MediaOceanInvoice()

    invoice.id = data.id
    invoice.name = data.name
    invoice.status = data.status
    invoice.type = data.type
    invoice.total_amount = data.total_amount
    invoice.created_at = data.created_at
    invoice.updated_at = data.updated_at
    invoice.invoice_mode = data.invoice_mode
    if (data.media_plan_id) invoice.media_plan_id = data.media_plan_id

    // Agency
    invoice.agency.id = data.agency.id
    invoice.agency.name = data.agency.name
    invoice.agency.address_line_1 = data.agency.address_line_1
    invoice.agency.address_line_2 = data.agency.address_line_2
    invoice.agency.address_line_3 = data.agency.address_line_3
    invoice.agency.address_line_4 = data.agency.address_line_4

    // Station
    invoice.station.call_letters = data.station.call_letters
    invoice.station.media_type = data.station.media_type
    invoice.station.band = data.station.band
    invoice.station.name = data.station.name
    invoice.station.address_line_1 = data.station.address_line_1
    invoice.station.address_line_2 = data.station.address_line_2
    invoice.station.address_line_3 = data.station.address_line_3
    invoice.station.address_line_4 = data.station.address_line_4
    invoice.station.gst_registration_number = data.station.gst_registration_number
    invoice.station.qst_registration_number = data.station.qst_registration_number

    // Payee
    invoice.payee.name = data.payee.name
    invoice.payee.address_line_1 = data.payee.address_line_1
    invoice.payee.address_line_2 = data.payee.address_line_2
    invoice.payee.address_line_3 = data.payee.address_line_3
    invoice.payee.address_line_4 = data.payee.address_line_4

    // Header
    invoice.header.representative = data.header.representative
    invoice.header.sales_person = data.header.sales_person
    invoice.header.advertiser_name = data.header.advertiser_name
    invoice.header.product_name = data.header.product_name
    invoice.header.date = data.header.date
    invoice.header.order_type = data.header.order_type
    invoice.header.agency_estimate_code = data.header.agency_estimate_code
    invoice.header.number = data.header.number
    invoice.header.broadcast_month = data.header.broadcast_month
    invoice.header.start_date = data.header.start_date
    invoice.header.end_date = data.header.end_date
    invoice.header.schedule_start_date = data.header.schedule_start_date
    invoice.header.schedule_end_date = data.header.schedule_end_date
    invoice.header.contract_start_date = data.header.contract_start_date
    invoice.header.contract_end_date = data.header.contract_end_date
    invoice.header.billing_insctructions = data.header.billing_insctructions
    invoice.header.rate_card_number = data.header.rate_card_number
    invoice.header.agency_comission_flag = data.header.agency_comission_flag
    invoice.header.sales_tax = data.header.sales_tax
    invoice.header.audience_percent = data.header.audience_percent
    invoice.header.rep_order_number = data.header.rep_order_number
    invoice.header.station_order_number = data.header.station_order_number
    invoice.header.station_trade_order_number = data.header.station_trade_order_number ?? ''
    invoice.header.station_advertiser_code = data.header.station_advertiser_code
    invoice.header.agency_advertiser_code = data.header.agency_advertiser_code
    invoice.header.station_product_code = data.header.station_product_code
    invoice.header.agency_product_code = data.header.agency_product_code
    invoice.header.station_contact = data.header.station_contact
    invoice.header.agency_contact = data.header.agency_contact
    invoice.header.due_date = data.header.due_date
    invoice.header.network = data.header.network
    invoice.header.trading_partner_code = data.header.trading_partner_code
    invoice.header.deal_number = data.header.deal_number
    invoice.header.rep_id = data.header.rep_id
    invoice.header.package_code = data.header.package_code
    invoice.header.reference_invoice_number = data.header.reference_invoice_number
    invoice.header.reference_invoice_code = data.header.reference_invoice_code
    invoice.header.invoice_version_code = data.header.invoice_version_code
    invoice.header.national_local_code = data.header.national_local_code
    invoice.header.special_paying_rep_code = data.header.special_paying_rep_code

    // Totals
    invoice.totals.invoice_confirmed_cost = data.totals.invoice_confirmed_cost
    invoice.totals.actual_gross_billing = data.totals.actual_gross_billing
    invoice.totals.agency_comission = data.totals.agency_comission
    invoice.totals.net_due = data.totals.net_due
    invoice.totals.reconciliation_dr = data.totals.reconciliation_dr
    invoice.totals.reconciliation_cr = data.totals.reconciliation_cr
    invoice.totals.reconciliation_total = data.totals.reconciliation_total
    invoice.totals.state_tax = data.totals.state_tax
    invoice.totals.local_tax = data.totals.local_tax
    invoice.totals.prior_gross_balance = data.totals.prior_gross_balance
    invoice.totals.prior_net_balance = data.totals.prior_net_balance
    invoice.totals.number_of_spots = data.totals.number_of_spots
    invoice.totals.gst = data.totals.gst
    invoice.totals.pst = data.totals.pst

    // Transmission
    invoice.trasmission.invoices = data.trasmission.invoices
    invoice.trasmission.total = data.trasmission.total

    if (data.broadcasts) {
      invoice.broadcasts = MediaOceanInvoiceItem.toObjectList(data.broadcasts)
    }

    //  Cache Object List
    if (cache) MediaOceanInvoice.module.update(invoice)

    return invoice
  }

  public static toObjectList(data: any, cache: boolean = true): MediaOceanInvoice[] {
    const invoices = new Array<MediaOceanInvoice>()
    data.forEach((value: any) => {
      const invoice = MediaOceanInvoice.toObject(value, false)
      invoices.push(invoice)
    })

    //  Cache Object List
    if (cache) MediaOceanInvoice.module.update(invoices)

    return invoices
  }

  /// State Management
  public static get module(): MediaOceanInvoiceModule {
    if (!store.hasModule('media_ocean_invoice')) {
      store.registerModule('media_ocean_invoice', MediaOceanInvoiceModule)
    }

    return getModule(MediaOceanInvoiceModule)
  }

  public static find(id: string): MediaOceanInvoice | null {
    const o = MediaOceanInvoice.module.data.find(
      media_ocean_invoice => media_ocean_invoice.id === id,
    )
    return o instanceof MediaOceanInvoice ? o : null
  }

  public static filter(filter: string[]): MediaOceanInvoice[] {
    if (Array.isArray(filter)) {
      return MediaOceanInvoice.module.data.filter(
        media_ocean_invoice => media_ocean_invoice.id && filter.includes(media_ocean_invoice.id),
      )
    }
    return MediaOceanInvoice.module.data.filter(filter)
  }

  public static async get(id: string): Promise<MediaOceanInvoice | null> {
    return MediaOceanInvoice.module.find(id)
  }

  public static async paginate(options: PaginateOptions) {
    return MediaOceanInvoice.module.paginate(options)
  }

  private randomUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0
      const v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  public static tableFields: any = tableFields
}
