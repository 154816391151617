/** @format */

import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import MediaOceanInvoice from '@/models/MediaOceanInvoice'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'media_ocean_invoice',
})
export default class MediaOceanInvoiceModule extends VuexModule {
  public data: MediaOceanInvoice[] = [];

  public options: SelectOption[] = [];

  public synchronized: boolean = false;

  public get api() {
    return new Api(false)
  }

  @Mutation
  public setSynchronized(status: boolean) {
    this.synchronized = status
  }

  @Mutation
  public update(media_ocean_invoices: MediaOceanInvoice | MediaOceanInvoice[]) {
    let data: Array<MediaOceanInvoice> = []
    if (media_ocean_invoices instanceof MediaOceanInvoice) {
      data.push(media_ocean_invoices)
    } else {
      data = media_ocean_invoices
    }

    let result = this.data

    data.forEach((media_ocean_invoice: MediaOceanInvoice) => {
      let found: boolean = false
      result = result.map((obj: MediaOceanInvoice) => {
        if (obj.id == media_ocean_invoice.id) {
          found = true
          return media_ocean_invoice
        }
        return obj
      })

      if (!found) {
        result.push(media_ocean_invoice)
      }
    })

    this.data = result
  }

  @Mutation
  public delete(media_ocean_invoices: MediaOceanInvoice | MediaOceanInvoice[]) {
    let data: Array<MediaOceanInvoice> = []
    if (media_ocean_invoices instanceof MediaOceanInvoice) {
      data.push(media_ocean_invoices)
    } else {
      data = media_ocean_invoices
    }

    let result = this.data

    data.forEach((media_ocean_invoice: MediaOceanInvoice) => {
      result = result.filter((obj: MediaOceanInvoice) => obj.id != media_ocean_invoice.id)
    })

    this.data = result
  }

  @Action
  public async find(id: string): Promise<MediaOceanInvoice | null> {
    return new Promise(resolve => {
      const o = this.data.find(
        media_ocean_invoice => media_ocean_invoice.id === id,
      )

      if (o instanceof MediaOceanInvoice) {
        resolve(o)
      } else {
        return this.api
          .get(`media_ocean_invoice/${id}`)
          .then(response => {
            // Parse & cache data
            const data = MediaOceanInvoice.toObject(response.data.result.invoice)

            if (data instanceof MediaOceanInvoice) {
              resolve(data)
            }
          })
          .catch(() => {
            resolve(null)
          })
      }
    })
  }

  @Action
  public async paginate(options: PaginateOptions) {
    return this.api
      .get('media_ocean_invoice/paginate', options)
      .then(response => {
        // Parse & cache data
        const data = MediaOceanInvoice.toObjectList(response.data.result.results)

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  @Action
  public async searchOptions(query: any) {
    return this.api
      .get('media_ocean_invoice/search/option', query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }

  @Action
  public async searchPropertyOptions(query: any) {
    return this.api
      .get('media_ocean_invoice/properties/search/option', query)
      .then(response => {
        const options = SelectOption.toObjectList(response.data.result.options)

        return options.filter(
          (elem, index) => options.findIndex(x => x.value === elem.value) === index,
        )
      })
      .catch(() => [])
  }
}
