
import {
  Component, Prop, Vue, Ref, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import MediaOceanInvoice from '@/models/MediaOceanInvoice'
import MediaOceanInvoiceItem from '@/models/MediaOceanInvoiceItem'
import WebMessage from '@/models/WebMessage'
import InsertionOrderPicker from '@/components/InsertionOrderPicker/InsertionOrderPicker.vue'
import InsertionOrderItemPicker from '@/components/InsertionOrderItemPicker/InsertionOrderItemPicker.vue'
import MediaPlan from '@/models/MediaPlan'
import MediaPlanItem from '@/models/MediaPlanItem'
import moment from 'moment'
import { InvoiceUploadMode } from '@/models/interface/Common'
import FormButtons from './FormButtons.vue'

@Component({
  // @ts-ignore
  components: {
    Widget,
    DatePicker,
    SelectPicker,
    FormWizard,
    TabContent,
    FormInput,
    IconAction,
    InsertionOrderPicker,
    InsertionOrderItemPicker,
    FormButtons,
  },
})
export default class MediaOceanInvoiceEdit extends ViewModel {
  @Ref() readonly formWizard!: any

  @Prop()
  public id!: string

  public loading: boolean = false

  public show_all_fields: boolean = false

  public action: string = 'delete'

  public modal: boolean = false

  public selected: string[] = []

  public batch_item: MediaOceanInvoiceItem = new MediaOceanInvoiceItem()

  public item: MediaOceanInvoiceItem | null = null

  public media_plan: MediaPlan | null = null

  public line_items: MediaPlanItem[] = []

  public busy = true

  public step: number = 1

  public get upload_mode_options() {
    return InvoiceUploadMode
  }

  public type_options = [
    {
      name: 'Media Ocean',
      value: 'media_ocean',
    },
    {
      name: 'Strata',
      value: 'strata',
    },
  ]

  public media_types = [
    {
      name: 'TV',
      value: 'TV',
    },
    {
      name: 'R',
      value: 'R',
    },
    {
      name: 'LC',
      value: 'LC',
    },
    {
      name: 'NC',
      value: 'NC',
    },
    {
      name: 'N',
      value: 'N',
    },
    {
      name: 'X',
      value: 'X',
    },
  ]

  public band_types = [
    {
      name: 'AM',
      value: 'AM',
    },
    {
      name: 'FM',
      value: 'FM',
    },
    {
      name: 'X',
      value: 'X',
    },
    {
      name: 'TV',
      value: 'TV',
    },
    {
      name: 'SM',
      value: 'SM',
    },
    {
      name: 'DV',
      value: 'DV',
    },
  ]

  public bool_options = [
    {
      name: 'Yes',
      value: 'Y',
    },
    {
      name: 'No',
      value: 'N',
    },
  ]

  public national_local_options = [
    {
      name: 'National',
      value: 'N',
    },
    {
      name: 'Local',
      value: 'L',
    },
  ]

  public day_of_week_options = [
    {
      name: 'Monday',
      value: '1',
    },
    {
      name: 'Tuesday',
      value: '2',
    },
    {
      name: 'Wednesday',
      value: '3',
    },
    {
      name: 'Thursday',
      value: '4',
    },
    {
      name: 'Friday',
      value: '5',
    },
    {
      name: 'Saturday',
      value: '6',
    },
    {
      name: 'Sunday',
      value: '7',
    },
  ]

  public spot_length = [
    {
      name: '15',
      value: '15',
    },
    {
      name: '30',
      value: '30',
    },
    {
      name: '60',
      value: '60',
    },
  ]

  private order_types: any = [
    {
      name: 'Cash',
      value: 'cash',
    },
    {
      name: 'Trade',
      value: 'trade',
    },
  ]

  public invoice = new MediaOceanInvoice()

  public title = 'Create Media Ocean Invoice'

  public confirmIOSync() {
    if (this.media_plan) {
      this.action = 'sync'
      this.modal = true
    }
  }

  public toggleFields() {
    this.show_all_fields = !this.show_all_fields
  }

  @Watch('invoice.media_plan_id')
  public onChangeMediaPlan() {
    if (
      this.invoice.media_plan_id
      && (!this.media_plan || this.media_plan.id !== this.invoice.media_plan_id)
    ) {
      MediaPlan.find(this.invoice.media_plan_id).then(media_plan => {
        this.media_plan = media_plan

        // Break existing Item Links if not valid
        this.invoice.broadcasts.forEach(b => {
          if (
            b.media_plan_item_id
            && !this.media_plan?.line_items.some(i => i.id === b.media_plan_item_id)
          ) {
            b.media_plan_item_id = ''
          }
        })
        this.updateInvoiceName()
        if (this.id === null) {
          this.syncIOInfo()
        } else {
          WebMessage.success(
            'IO Link updated! You can use the action below to update the Invoice with the IO Details',
            [
              {
                text: 'Overwrite data with IO Details',
                action: (toast: any) => {
                  WebMessage.hide(toast.id)
                  this.syncIOInfo()
                },
              },
            ],
          )
        }
      })
    } else {
      this.media_plan = null
    }
  }

  public syncIOInfo() {
    if (this.media_plan) {
      if (this.media_plan.isLinear) {
        // Copy Agency Info
        this.invoice.agency.id = this.media_plan.metadata.agency.id
        this.invoice.agency.name = this.media_plan.metadata.agency.name
        this.invoice.agency.address_line_1 = this.media_plan.metadata.agency.address_line_1
        this.invoice.agency.address_line_2 = this.media_plan.metadata.agency.address_line_2
        this.invoice.agency.address_line_3 = this.media_plan.metadata.agency.address_line_3
        this.invoice.agency.address_line_4 = this.media_plan.metadata.agency.address_line_4

        // Copy Station Info
        this.invoice.station.call_letters = this.media_plan.metadata.station.call_letters

        // Copy Header Info
        this.invoice.header.representative = this.media_plan.metadata.header.representative
        this.invoice.header.advertiser_name = this.media_plan.metadata.header.advertiser_name
        this.invoice.header.product_name = this.media_plan.metadata.header.product_name
        this.invoice.header.agency_estimate_code = this.media_plan.metadata.header.agency_estimate_code
        this.invoice.header.start_date = this.media_plan.metadata.header.start_date
        this.invoice.header.end_date = this.media_plan.metadata.header.end_date
        this.invoice.header.station_order_number = this.media_plan.metadata.header.station_order_number
        this.invoice.header.station_trade_order_number = this.media_plan.metadata.header.station_trade_order_number
        this.invoice.header.agency_advertiser_code = this.media_plan.metadata.header.agency_advertiser_code
        this.invoice.header.agency_product_code = this.media_plan.metadata.header.agency_product_code

        if (this.media_plan.metadata.invoice_mode) {
          this.invoice.invoice_mode = this.media_plan.metadata.invoice_mode
        }
      }

      this.invoice.header.sales_person = this.media_plan.sales_rep
        ? this.media_plan.sales_rep.name
        : ''

      this.formWizard.reset()

      WebMessage.success(
        'Invoice Information was successfully updated! Please reveiew all fields to ensure the data is correct.',
      )
    }
  }

  public preloadIOLineItems() {
    const base = moment(`${this.invoice.header.broadcast_month}01`, 'YYMMDD')
    const start = base
      .clone()
      .startOf('week')
      .add(1, 'days')
    const end = base
      .clone()
      .endOf('month')
      .endOf('week')
      .add(1, 'days')
    if (end.month() > base.month()) {
      end.subtract(1, 'week')
    }

    const elegible_items = this.media_plan?.line_items.filter(media_plan_item => {
      let item_start_at = moment(media_plan_item.start_at)
      let item_end_at = moment(media_plan_item.end_at)

      return (
        item_start_at.isBetween(start, end, undefined, '[]')
        || item_end_at.isBetween(start, end, undefined, '[]')
        || (item_start_at.isSameOrBefore(end) && item_end_at.isSameOrAfter(start))
      )
    })
    if (elegible_items) {
      elegible_items.forEach(media_plan_item => {
        // Add Item if not present
        if (!this.invoice.broadcasts.some(b => b.media_plan_item_id === media_plan_item.id)) {
          this.invoice.addItem()
          const b = this.invoice.broadcasts[this.invoice.broadcasts.length - 1]
          if (typeof media_plan_item.id === 'string') b.media_plan_item_id = media_plan_item.id
          this.invoice.broadcasts[this.invoice.broadcasts.length - 1] = this.syncBroadcastItem(
            b,
            media_plan_item,
          )
        }
      })
      this.watchBroadcastItems()
    }
  }

  public watchBroadcastItems() {
    // Watch IO Item ID
    this.invoice.broadcasts.forEach(b => {
      if (!b.has_listener) {
        b.has_listener = true
        this.$watch(
          () => b.media_plan_item_id,
          target => {
            if (this.media_plan) {
              const item = this.media_plan.line_items.find(i => i.id === target)
              b = this.syncBroadcastItem(b, item)
              WebMessage.success(
                'Item rate and impressions updated, please ensure numbers are correct',
              )
            }
          },
        )
      }
    })
  }

  public syncBroadcastItem(
    b: MediaOceanInvoiceItem,
    item: MediaPlanItem | undefined,
  ): MediaOceanInvoiceItem {
    if (item) {
      b.impressions = item.metrics.impressions
      b.run_date = moment(item.schedule_start_at).format('YYMMDD')
      b.program_name = item.metadata.program_name ?? item.name
      b.rate = item.gross_cost.toFixed(2).replaceAll('.', '')
      b.copy_id = item.topISCI
      b.order_type = item.metadata.order_type.toLowerCase()
      b.type = String(item.creative_length) ?? '30'
      if (this.media_plan) b.video_copy_id = MediaPlan.buildVideoCopyId(this.media_plan, item)
    }

    return b
  }

  public cancel() {
    this.$router.push({ name: 'MediaOceanInvoiceHome' })
  }

  public onSubmit() {
    this.loading = true
    this.invoice
      .save()
      .then(response => {
        this.loading = false
        if (response.status == 200) {
          this.$router.push({ name: 'MediaOceanInvoiceHome' })
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public viewIO() {}

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (this.step === 5 && nextIndex === 5) {
      this.updateTotals()
      WebMessage.warning('The totals were updated, please ensure the numbers are correct')
    } else if (this.step === 4 && nextIndex === 4) {
      if (this.invoice.broadcasts.length === 0 && this.media_plan) {
        this.preloadIOLineItems()
      }
      if (this.invoice.broadcasts.length === 0) {
        this.invoice.addItem()
        // Watch IO Item ID
        this.watchBroadcastItems()
      }
    }

    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }
  }

  public updateTotals() {
    this.invoice.updateTotals()
  }

  public created() {
    const { query } = this.$route

    if (this.id) {
      this.loading = true
      this.busy = true
      MediaOceanInvoice.get(this.id).then(o => {
        if (o instanceof MediaOceanInvoice) {
          this.title = 'Edit Media Ocean Invoice'

          this.invoice = o

          // Watch IO Item ID
          this.watchBroadcastItems()

          this.loading = false
          this.busy = false

          setTimeout(() => {
            // @ts-ignore
            if (!this.invoice.is_legacy) this.formWizard.activateAll()
          }, 1000)
        } else {
          this.newInvoice()
        }
      })
    } else if (query.from && typeof query.from === 'string') {
      this.loading = true
      this.busy = true
      MediaOceanInvoice.get(query.from).then(o => {
        if (o instanceof MediaOceanInvoice) {
          this.title = 'Create Media Ocean Invoice'

          this.invoice = MediaOceanInvoice.toObject(o)
          this.invoice.id = null

          this.loading = false
          this.busy = false
        } else {
          this.newInvoice()
        }
      })
    } else {
      this.newInvoice()
    }
  }

  public cloneItem(number: number) {
    this.invoice.clone(number)
    // Watch IO Item ID
    this.watchBroadcastItems()
  }

  public updateInvoiceName() {
    if (
      this.invoice.name === ''
      && this.invoice.media_plan_id
      && this.media_plan
      && this.invoice.header.number
    ) {
      this.invoice.name = `${this.media_plan.name} ${this.invoice.header.number}`
    }
  }

  public newItem() {
    this.invoice.addItem()
    // Watch IO Item ID
    this.watchBroadcastItems()
  }

  public newInvoice() {
    this.busy = false
    this.loading = false
  }

  public batchEdit() {
    this.action = 'batch'
    this.modal = true
  }

  public deleteItem(item: MediaOceanInvoiceItem) {
    this.item = item
    this.action = 'delete'
    this.modal = true
  }

  public isEnabled(name: string) {
    return this.selected.includes(name)
  }

  public modalConfirm() {
    if (this.action === 'batch') {
      const items = MediaOceanInvoiceItem.toObjectList(this.invoice.broadcasts)
      this.selected.forEach(prop => {
        items.forEach(item => {
          // @ts-ignore
          Vue.set(item, prop, this.batch_item[prop])
        })
      })

      this.invoice.broadcasts = items
    } else if (this.action === 'delete' && this.item) {
      this.invoice.removeItem(this.item.number)
    } else if (this.action === 'sync') {
      this.syncIOInfo()
    }
  }
}
