/** @format */

import moment from 'moment'

export default class MediaOceanInvoiceItem {
  public run_code = 'Y';

  public run_date = '';

  public time_of_day = '1900';

  public type = '30';

  public copy_id = '';

  public rate = '';

  public class = '';

  public piggyback = '';

  public makegood_date_1 = '';

  public makegood_date_2 = '';

  public makegood_time_1 = '';

  public makegood_time_2 = '';

  public make_good_line = '';

  public adjustment_dr = '';

  public adjustment_cr = '';

  public program_name = '';

  public impressions = 0;

  public billboard_indicator = '';

  public order_type = 'cash'

  public length = '';

  public video_copy_id = '';

  public audio_copy_id = '';

  public serial_number = '';

  public network = '';

  public integration_cost = '';

  public package = '';

  public id = '';

  public media_plan_item_id = '';

  public number = 0;

  public visible = false;

  public legacy = false;

  public has_listener = false;

  public get day_of_week() {
    return moment(this.run_date, 'YYMMDD').isoWeekday()
  }

  public get day_of_week_human() {
    return moment(this.run_date, 'YYMMDD').format('dddd')
  }

  public get program_description() {
    return this.impressions && this.impressions > 0
      ? `${this.program_name} - ${(this.impressions / 1000).toFixed(
        1,
      )} impressions`
      : this.program_name
  }

  public static toObject(
    data: any,
    cache: boolean = true,
  ): MediaOceanInvoiceItem {
    const invoice = new MediaOceanInvoiceItem()

    for (const key in data) {
      if (
        key != 'visible'
        && key != 'day_of_week'
        && key != 'day_of_week_human'
      ) {
        // Check if legacy format
        if (!data.program_name) {
          invoice.legacy = true
        }
        if (
          invoice.legacy
          && key === 'program_description'
          && typeof data.program_description === 'string'
        ) {
          const d = data.program_description.split('-')
          if (d[0] && d[1]) {
            invoice.impressions = parseFloat(d[1].replace('impressions', '')) * 1000
            invoice.program_name = d[0].trim()
          } else {
            invoice.impressions = 0
            invoice.program_name = data.program_description.trim()
          }
        } else if (key !== 'program_description') {
          // @ts-ignore
          invoice[key] = data[key]
        }
      }
    }

    return invoice
  }

  public static toObjectList(
    data: any,
    cache: boolean = true,
  ): MediaOceanInvoiceItem[] {
    const invoices = new Array<MediaOceanInvoiceItem>()
    data.forEach((value: any) => {
      const invoice = MediaOceanInvoiceItem.toObject(value, false)
      invoices.push(invoice)
    })

    return invoices
  }

  public toJSON() {
    return {
      run_code: this.run_code,
      run_date: this.run_date,
      day_of_week: this.day_of_week,
      time_of_day: this.time_of_day,
      type: this.type,
      copy_id: this.copy_id,
      rate: this.rate,
      class: this.class,
      piggyback: this.piggyback,
      order_type: this.order_type,
      makegood_date_1: this.makegood_date_1,
      makegood_date_2: this.makegood_date_2,
      makegood_time_1: this.makegood_time_1,
      makegood_time_2: this.makegood_time_2,
      make_good_line: this.make_good_line,
      adjustment_dr: this.adjustment_dr,
      adjustment_cr: this.adjustment_cr,
      program_description: this.program_description,
      program_name: this.program_name,
      impressions: this.impressions,
      billboard_indicator: this.billboard_indicator,
      length: this.length,
      video_copy_id: this.video_copy_id,
      audio_copy_id: this.audio_copy_id,
      serial_number: this.serial_number,
      network: this.network,
      integration_cost: this.integration_cost,
      package: this.package,
      id: this.id,
      number: this.number,
      media_plan_item_id: this.media_plan_item_id,
    }
  }
}
