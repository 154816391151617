import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import router from '@/Routes'
import Vue from 'vue'
import MediaOceanInvoiceExport from '@/pages/MediaOcean/Invoice/Components/MediaOceanInvoiceExport.vue'
import MediaOceanInvoice from '../MediaOceanInvoice'
import WebMessage from '../WebMessage'

const { user } = getModule(SystemtModule)

export const model_name = 'media_ocean_invoice'

export const tableFields: any = [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    show: true,
    type: 'checkbox',
  },
  {
    key: 'invoice_number',
    label: '#',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'header',
      property: 'number',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    show: true,
    type: 'capitalize',
    tdClass: 'align-middle text-center',
    formatter: (value: any, key: string, invoice: MediaOceanInvoice) =>
      invoice.type.replaceAll('_', ' '),
  },
  {
    key: 'invoice_mode',
    label: 'Upload Mode',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
    formatter: (value: any) => (value === 'ftp' ? 'FTP (OX)' : 'Invoice Manager (DS)'),
  },
  {
    key: 'broadcast_month',
    label: 'Broadcast Month',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'header',
      property: 'broadcast_month',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'order_type',
    label: 'Order Type',
    sortable: false,
    show: true,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'gross_amount',
    label: 'Gross Amount',
    sortable: false,
    show: false,
    type: 'currency',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'gross_cash',
    label: 'Gross Cash',
    sortable: false,
    show: true,
    tdClass: 'align-middle text-center',
    formatter: (value: any) =>
      (value === 0 ? '-' : `$ ${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`),
  },
  {
    key: 'gross_trade',
    label: 'Gross Trade',
    sortable: false,
    show: true,
    tdClass: 'align-middle text-center',
    formatter: (value: any) =>
      (value === 0 ? '-' : `$ ${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`),
  },
  {
    key: 'net_amount',
    label: 'Net Amount',
    sortable: false,
    show: true,
    type: 'currency',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'total_amount',
    label: 'Finance Amount',
    sortable: false,
    show: true,
    tdClass: 'align-middle text-center',
    formatter: (value: any) =>
      (value ? `$ ${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : '-'),
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    show: true,
    type: 'badge',
    color: (invoice: MediaOceanInvoice) => invoice.status_color,
    formatter: (value: any, key: string, invoice: MediaOceanInvoice) =>
      invoice.status.replaceAll('_', ' '),
    tdClass: 'align-middle text-center',
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    show: false,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    sortable: true,
    show: true,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: '',
    sortable: false,
    show: true,
    type: 'action_list',
    actions: [
      {
        icon: 'pencil',
        title: 'Edit',
        event: 'edit',
        show: () => user && user.can('media_ocean', 'edit'),
        action: (invoice: MediaOceanInvoice) => {
          router.push(`/app/mediaocean_invoice/${invoice.id}`)
        },
      },
      {
        icon: 'copy',
        title: 'Clone',
        event: 'clone',
        show: () => user && user.can('media_ocean', 'edit'),
        action: (invoice: MediaOceanInvoice) => {
          router.push(`/app/mediaocean_invoice/?from=${invoice.id}`)
        },
      },
      {
        icon: 'file-export',
        title: 'Export',
        event: 'export',
        action: (invoice: MediaOceanInvoice, dataTable: any) => {
          let Component = Vue.extend(MediaOceanInvoiceExport)
          let instance = new Component({
            propsData: {
              selected: [invoice.id],
              mode: 'selected',
            },
          })
          instance.$mount()
        },
      },
      {
        icon: 'calculator',
        title: 'Recalculate',
        event: 'recalculate',
        show: (invoice: MediaOceanInvoice) => user && user.can('media_ocean', 'edit'),
        action: (invoice: MediaOceanInvoice, dataTable: any) =>
          WebMessage.confirm(
            `Are you sure that you want to recalculate the Invoice "<strong>#${invoice.header.number} - ${invoice.name}</strong>"? This action will recreate the invoice based on the Media Plan!`,
            'Recalculate Invoice',
            { okTitle: 'Recalculate' },
          ).then((value: boolean) => {
            if (value) {
              return invoice.recalculate()
            }
            return value
          }),
      },
      {
        icon: 'trash',
        title: 'Delete',
        event: 'delete',
        show: (invoice: MediaOceanInvoice) => user && user.can('media_ocean', 'delete'),
        action: (invoice: MediaOceanInvoice) =>
          WebMessage.confirm(
            `Are you sure that you want to delete the Invoice "<strong>#${invoice.header.number} - ${invoice.name}</strong>"? You won't be able to restore it!`,
            'Delete Invoice',
          ).then((value: boolean) => {
            if (value) {
              invoice.delete()
            }
            return value
          }),
      },
    ],
  },
]
