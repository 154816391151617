
import { Component, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import FormInput from '@/components/FormInput/FormInput.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import moment from 'moment'
import Api from '@/models/Api'
import WebMessage from '@/models/WebMessage'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'

@Component({
  components: {
    FormInput,
    SelectPicker,
  },
})
export default class MediaOceanInvoiceExport extends ViewModel {
  @Prop({ default: () => [] })
  public selected!: string[]

  public modal = true

  public type = 'download'

  public order_type = 'all'

  @Prop({ default: 'download' })
  public mode!: string

  public broadcast_month = moment()
    .subtract(1, 'month')
    .format('YYMM')

  private type_options = [
    {
      name: 'Download',
      value: 'download',
    },
    {
      name: 'Upload to Media Ocean',
      value: 'upload',
    },
  ]

  private mode_options = [
    {
      name: 'Selected Invoices',
      value: 'selected',
    },
    {
      name: 'Broadcast Month',
      value: 'broadcast_month',
    },
  ]

  private order_type_options = [
    {
      name: 'Cash & Trade',
      value: 'all',
    },
    {
      name: 'Cash Only',
      value: 'cash',
    },
    {
      name: 'Trade Only',
      value: 'trade',
    },
  ]

  public confirm() {
    const api = new Api()
    const instance_id = getModule(SystemtModule)._uuid
    let data: any = {}
    if (this.mode === 'selected') {
      if (this.selected.length === 0) {
        WebMessage.error('Please select at least 1 item.')
        return
      }
      data = {
        invoices: this.selected,
        order_type: this.order_type,
        instance_id,
      }
    } else {
      data = {
        broadcast_month: this.broadcast_month,
        order_type: this.order_type,
        instance_id,
      }
    }
    api.post(`media_ocean_invoice/export/${this.type}`, data).then(() => {
      if (this.type === 'download') {
        WebMessage.success('Generating Files, do not close this window!')
      } else {
        WebMessage.success(
          'Uploading Invoices to Media Ocean, we will let you know once the process is complete!',
        )
      }
      this.modal = false
      this.$destroy()
      this.$el.parentNode?.removeChild(this.$el)
    })
  }

  public cancel() {
    this.modal = false
    this.$destroy()
    this.$el.parentNode?.removeChild(this.$el)
  }
}
